import { useQuery } from "react-query";
import { graphQLClient } from "../api";
import { QUERY_PERSONAL_PORTFOLIO } from "../api/graphql-request-query/personal-portfolio";
import { QUERY_LANDING_PAGE_BY_SLUG } from "../api/graphql-request-query/landing-page-by-slug";

export function useLandingPageBySlug(slug) {
    return useQuery(["landing-page", slug], async () => {
        const resp = await graphQLClient.request(QUERY_LANDING_PAGE_BY_SLUG, {
            slug
        });
        return resp;
    }, {
        enabled: slug ? true : false,
    });
}