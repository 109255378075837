import React, { Fragment, Profiler, useEffect, useMemo, useState } from "react";
import ScrollToTop from "react-scroll-up";
import ModalVideo from "react-modal-video";
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import HeaderTwo from "../component/header/HeaderTwo";
import FooterTwo from "../component/footer/FooterTwo";
import BrandTwo from "../elements/BrandTwo";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ContactOne from "../elements/contact/ContactOne";
import CallAction from "../elements/callaction/CallAction";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { usePersonalPortfolio } from "../hooks/usePortfolio";
import DarkFullScreenLoader from "../dark/DarkFullScreenLoader";
import TextLoop from "react-text-loop";
import { cmsAssetUrl } from "../utils/asset-url";
import classNames from "classnames";
import { ProgressBar } from "react-bootstrap";
import FormStyle from "../elements/contact/FormStyle";
import { Link } from "react-router-dom";
import { TabList, TabPanel, Tabs, Tab } from "react-tabs";

const PersonalPortfolioDynamic = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false);
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };
  const params = useParams();
  const { isLoading: isDataLoading, data: cmsData } = usePersonalPortfolio(
    params.username
  );
  const portfolio = useMemo(() => {
    if (!cmsData?.personal_portfolio?.length) {
      return null;
    }
    return cmsData.personal_portfolio[0];
  }, [cmsData?.personal_portfolio]);
  if (!params.username) {
    console.log(`username not found`);
    return <Redirect to='/404' />;
  }
  if (isDataLoading) {
    return <DarkFullScreenLoader />;
  }
  if (!isDataLoading && !portfolio) {
    console.log(`data not found`);
    return <Redirect to='/404' />;
  }
  if (!portfolio) {
    return <></>;
  }
  const SlideList = [
    {
      textPosition: "text-left",
      category: portfolio?.keywords || "Freelance digital designer",
      title: portfolio?.name || "Hello, I’m <span>Fatima.</span>",
      description:
        portfolio?.description ||
        "I’m working on a professional, visually sophisticated and technologically <br /> proficient, responsive and multi-functional React Template Imroz.",
      buttonText: portfolio?.cta_text,
      buttonLink: portfolio?.cta_link,
    },
  ];
  // constructor() {
  //   super();
  //   this.state = {
  //     isOpen: false,
  //   };
  //   this.openModal = this.openModal.bind(this);
  // }
  // openModal() {
  //   this.setState({ isOpen: true });
  // }
  // render() {
  var namesItemOne = [
    "The Philosophy Of business analytics",
    "Fast-Track Your business",
    "Fast-Track Your business",
    "Lies And Damn Lies About business",
  ];
  let title = "About Me",
    description =
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,";
  return (
    <Fragment>
      <Helmet pageTitle='Personal Portfolio' />

      <HeaderTwo
        logo='symbol-dark'
        color='color-black'
        ctaLink={portfolio.cta_link}
        ctaText={portfolio.cta_text}
      />

      {/* Start Slider Area   */}
      <div className='slider-wrapper'>
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className={classNames(
              "slide slider_fixed_height slider-style-3 d-flex align-items-center justify-content-center white-overlay-mobile"
            )}
            style={{
              backgroundImage: portfolio.cover_image?.filename_disk
                ? `url(${cmsAssetUrl(
                  portfolio.cover_image?.filename_disk,
                  1920
                )})`
                : undefined,
              backgroundRepeat: 'no-repeat'
            }}
            key={index}>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? (
                      <span className='theme-gradient font-500'>
                        {/* {value.category} */}
                        <TextLoop>
                          {portfolio.keywords.map((k) => (
                            <span className='theme-gradient font-500' key={k}>
                              {k}
                            </span>
                          ))}
                        </TextLoop>
                      </span>
                    ) : (
                      ""
                    )}
                    {value.title ? (
                      <h1
                        className='title'
                        dangerouslySetInnerHTML={{
                          __html: value.title,
                        }}></h1>
                    ) : (
                      ""
                    )}
                    {value.description ? (
                      <p
                        className='description'
                        dangerouslySetInnerHTML={{
                          __html: value.description,
                        }}></p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className='slide-btn'>
                        <a className='btn-default' href={`${value.buttonLink}`}>
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div className='about-area ptb--120  bg_color--1' id='about'>
        <div className='about-wrapper'>
          <div className='container'>
            <div className='row row--35 align-items-center'>
              <div className='col-lg-5'>
                <div className='thumbnail'>
                  <img
                    className='w-100'
                    src={
                      portfolio.about_image?.filename_disk
                        ? cmsAssetUrl(portfolio.about_image.filename_disk)
                        : "/assets/images/about/about-8.jpg"
                    }
                    alt='About Images'
                  />
                </div>
              </div>
              <div className='col-lg-7'>
                <div className='about-inner inner'>
                  <div className='section-title'>
                    <span className='subtitle'>Greetings</span>
                    <h2 className='title'>{title}</h2>

                    <div
                      className='description'
                      dangerouslySetInnerHTML={{
                        __html: portfolio.about_me,
                      }}></div>
                  </div>
                  <div className='row mt--40'>
                    <div>
                      {/* Start Tabs Area */}
                      <div className='tabs-area'>
                        <div className='container'>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <Tabs>
                                <TabList className={`${"tab-style--1"}`}>
                                  <Tab>Main skills</Tab>
                                  <Tab>Awards</Tab>
                                  <Tab>Experience</Tab>
                                  <Tab>Education</Tab>
                                </TabList>

                                <TabPanel>
                                  <div className='single-tab-content'>
                                    <div
                                      className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                      {(portfolio.main_skills || []).map(
                                        (ms) => (
                                          <div
                                            key={ms.skill.name}
                                            className='single-progress'>
                                            <h6 className='title'>
                                              {ms.skill.name}
                                            </h6>
                                            <ProgressBar
                                              now={ms.skill.percent}
                                            />
                                            <span className='label'>
                                              {ms.skill.percent}%
                                            </span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </TabPanel>

                                <TabPanel>
                                  <div className='single-tab-content'>
                                    <ul>
                                      {(portfolio.awards || []).map(
                                        (award) => (
                                          <li key={award.award.name}>
                                            <a href='/service'>
                                              {award.award.name}{" "}
                                              <span>
                                                - {award.award.place}
                                              </span>
                                            </a>{" "}
                                            {award.award.start} -{" "}
                                            {award.award.end}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </TabPanel>

                                <TabPanel>
                                  <div className='single-tab-content'>
                                    <ul>
                                      {(portfolio.experience || []).map(
                                        (exp) => {
                                          return (
                                            <li
                                              key={exp.experience.job_title}>
                                              <a href='#'>
                                                {exp.experience.job_title}
                                                <span>
                                                  {" "}
                                                  - {exp.experience.job_place}
                                                </span>
                                              </a>{" "}
                                              {exp.experience.start} -{" "}
                                              {exp.experience.end}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </TabPanel>

                                <TabPanel>
                                  <div className='single-tab-content'>
                                    <ul>
                                      {(portfolio.education || []).map(
                                        (edu) => {
                                          return (
                                            <li key={edu.education.title}>
                                              <a href='#'>
                                                {edu.education.title}
                                                <span>
                                                  {" "}
                                                  - {edu.education.location}
                                                </span>
                                              </a>{" "}
                                              {edu.education.year}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                </TabPanel>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Tabs Area */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start Brand Area  */}
      {/* <div className='rn-brand-area pb--120 bg_color--1'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title text-center mb--25 mb_sm--0'>
                <span className='subtitle'>My Top clients</span>
                <h2 className='title'>Clients Meeting With Me</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1 mt--30'>
              <div className='thumbnail position-relative'>
                <img
                  className='w-100'
                  src='/assets/images/about/about-10.png'
                  alt='About Images'
                />
                <ModalVideo
                  channel='youtube'
                  isOpen={isOpen}
                  videoId='ZOoVOfieAF8'
                  onClose={() => setIsOpen(false)}
                />
                <button
                  className='video-popup position-top-center theme-color'
                  onClick={openModal}>
                  <span className='play-icon'></span>
                </button>
              </div>
            </div>
          </div>
          <div className='row pt--120'>
            <div className='col-lg-12'>
              <BrandTwo branstyle='branstyle--2' />
            </div>
          </div>
        </div>
      </div> */}
      {/* End Brand Area  */}

      {/* Start About Area  */}
      {/* <div className='rn-about-area pb--120 bg_color--1'>
        <div className='container'>
          <div className='row row--35 align-items-center'>
            <div className='col-lg-6'>
              <div className='thumbnail'>
                <img
                  className='w-100'
                  src='/assets/images/about/about-4.png'
                  alt='About Images'
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='about-inner inner'>
                <div className='section-title'>
                  <span className='subtitle'>My Performance</span>
                  <h2 className='title'>My Best Performance</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Enim exercitationem impedit iure quia quo recusandae?
                    adipisicing elit. Enim exercitationem impedit iure quia quo
                    recusandae?
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Enim exercitationem impedit iure quia quo recusandae?
                  </p>
                </div>
                <div className='accordion-wrapper mt--30'>
                  <ul className='list-style--1'>
                    {namesItemOne.map((name, index) => {
                      return (
                        <li key={index}>
                          <FiCheck /> {name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='about-button mt--30'>
                  <a className='btn-default' href='/about'>
                    See My Skill
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* End About Area  */}

      {/* Start Portfolio Area */}
      <div className='portfolio-area pb--60 bg_color--1' id='projects'>
        <div className='portfolio-sacousel-inner mb--55 mb_sm--0'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center mb--30 mb_sm--0'>
                  {/* <span className='subtitle'>Contact</span> */}
                  <h2 className='title'>Contact</h2>
                  {/* <p className='description'>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p> */}
                </div>
              </div>
            </div>
            <div className='row'>
              {/* <PortfolioList
                styevariation='text-center mt--40'
                column='col-lg-4 col-md-6 col-sm-6 col-12'
                item='3'
              /> */}
              <React.Fragment>
                {portfolio.projects.slice(0, 3).map((value, index) => (
                  <div
                    className='col-lg-4 col-md-6 col-sm-6 col-12'
                    key={index}>
                    <div className='im_portfolio text-center mt--40'>
                      <div className='thumbnail_inner'>
                        <div className='thumbnail'>
                          <Link to='/portfolio-details'>{value.image}</Link>
                        </div>
                      </div>
                      <div className='content'>
                        <div className='inner'>
                          <div className='portfolio_heading'>
                            <h4 className='title'>
                              <Link to='/portfolio-details'>{value.title}</Link>
                            </h4>
                          </div>
                          <div className='portfolio_hover'>
                            <p>{value.description}</p>
                          </div>
                        </div>
                      </div>
                      <Link
                        className='transparent_link'
                        to='/portfolio-details'></Link>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            </div>
            {/* <div className='row'>
              <div className='col-lg-12'>
                <div className='view-more-btn mt--60 mt_sm--30 text-center'>
                  <a className='btn-default' href='/blog'>
                    <span>View Projects</span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Portfolio Area */}
      <div className='portfolio-area pb--120 bg_color--1' id='contact'>
        <div className='contact-form--1'>
          <div className='container'>
            <div className='row row--35 align-items-start'>
              <div className='col-lg-6 order-2 order-lg-1'>
                <div className='section-title text-left mb--50'>
                  <span className='subtitle'>Let's Say Hi</span>
                  <h2 className='title'>Hire Me</h2>
                  <div className='im_address_inner'>
                    <div className='im_address'>
                      <span>Call us directly:</span>
                      <a
                        className='link im-hover'
                        href='#'>
                        {portfolio.phone_number || ''}
                      </a>
                    </div>
                    <div className='im_address mt--5'>
                      <span>Contact Email:</span>
                      <a
                        className='link im-hover'
                        href='mailto:example@gmail.com'>
                        {portfolio.contact_email}
                      </a>
                    </div>
                  </div>
                </div>
                <FormStyle />
              </div>
              <div className='col-lg-6 order-1 order-lg-2'>
                <div className='thumbnail mb_md--30 mb_sm--30'>
                  <img
                    src={
                      portfolio.contact_img?.filename_disk
                        ? cmsAssetUrl(portfolio.contact_img.filename_disk, 512)
                        : "/assets/images/about/about-13.jpg"
                    }
                    alt='trydo'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start call To Action  */}
      {/* <CallAction /> */}
      {/* End call To Action  */}

      <FooterTwo />

      {/* Start Back To Top */}
      <div className='backto-top'>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
};
// }

export default PersonalPortfolioDynamic;
