import { gql } from "graphql-request";

export const QUERY_BUSINESS_WEB_BY_SLUG = gql`query BusinessWebBySlug($slug: String!) {
    business_web(filter: {
        slug: {
            _eq: $slug
        }
    }) {
        hero_section {
            section: web_home_hero_section_id {
                title
                description
                image {
                    filename_disk
                }
                cta_link
                cta_text
            }
        }
        cta_navbar_link
        cta_navbar_text
        value_image {
            filename_disk
        }
        our_values {
            section: web_text_section_id {
                title
                description
            }
        }
        contact_email
        about_us
        about_services
    }
}

`