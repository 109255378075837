import { gql } from "graphql-request";

export const QUERY_PERSONAL_PORTFOLIO = gql`query Personal_portfolio($username: String) {
    personal_portfolio(filter: {
        username: {
            _eq: $username
        }
    }) {
        name
        keywords
        description
        cta_text
        cta_link
        about_me
        greetings
        contact_img {
            filename_disk
        }
        facebook_link
        linkedin_link
        instagram_link
        twitter_link
        cover_image {
            filename_disk
        }
        about_image {
            filename_disk
        }
        contact_email
        phone_number
        contact_title
        main_skills {
            skill: personal_portfolio_main_skill_id {
                name
                percent
            }
        }
        awards {
            award: personal_portfolio_award_id {
                name
                place
                start
                end
            }
        }
        projects {
            project: personal_portfolio_field_id {
                title
                desc
                img {
                    filename_disk
                }
            }
        }
        experience {
            experience: personal_portfolio_experience_id {
                job_title
                job_place
                start
                end
            }
        }
        education {
            education: personal_portfolio_education_id {
                title
                location
                year
            }
        }
        news {
            news: personal_portfolio_news_id {
                title
                description
                image {
                    filename_disk
                }
            }
        }
    }
}
`