import { useQuery } from "react-query";
import { graphQLClient } from "../api";
import { QUERY_BUSINESS_WEB_BY_SLUG } from "../api/graphql-request-query/business-web-by-slug";

export function useBusinessWebBySlug(slug) {
    return useQuery(["business-web", slug], async () => {
        const resp = await graphQLClient.request(QUERY_BUSINESS_WEB_BY_SLUG, {
            slug
        });
        return resp;
    }, {
        enabled: slug ? true : false,
    });
}