import React, { useEffect, Fragment, useMemo } from "react";
import Scrollspy from "react-scrollspy";
import ScrollToTop from "react-scroll-up";
import Particles from "react-tsparticles";
import { FiChevronUp, FiX, FiMenu, FiSend, FiCheck } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterTwo from "../elements/counters/CounterTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import { Redirect, useParams, Link } from "react-router-dom";
import { useLandingPageBySlug } from "../hooks/useLandingPageBySlug";
import DarkFullScreenLoader from "../dark/DarkFullScreenLoader";
import classNames from "classnames";
import { cmsAssetUrl } from "../utils/asset-url";
import FormStyle from "../elements/contact/FormStyle";

const Portfolio_image = (
  <img
    src='/assets/images/portfolio/interior/portfolio-15.jpg'
    alt='React Creative Agency'
  />
);
const Portfolio_image2 = (
  <img
    src='/assets/images/portfolio/interior/portfolio-12.jpg'
    alt='React Creative Agency'
  />
);
const Portfolio_image3 = (
  <img
    src='/assets/images/portfolio/interior/portfolio-13.jpg'
    alt='React Creative Agency'
  />
);
const Portfolio_image4 = (
  <img
    src='/assets/images/portfolio/interior/portfolio-14.jpg'
    alt='React Creative Agency'
  />
);

const PortfolioList = [
  {
    image: Portfolio_image,
    category: "Agency",
    title: "Agency Collection",
    description:
      "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit conse ctetur adipiscing elit.",
  },
  {
    image: Portfolio_image2,
    category: "Home Design",
    title: "Office Managemnt",
    description:
      "Lorem ipsum dolor sit amet, consec tetur adipiscing elit conse ctetur adipiscing elit.",
  },
  {
    image: Portfolio_image3,
    category: "Company",
    title: "Company Inner",
    description:
      "Lorem ipsum dolor sit amet, consec tetur adipiscing elit conse ctetur adipiscing elit.",
  },
  {
    image: Portfolio_image4,
    category: "Office Interior",
    title: "Creative Agency",
    description:
      "Lorem ipsum dolor sit amet, consec tetur adipiscing elit conse ctetur adipiscing elit.",
  },
];

function HomeParticlesDynamic(props) {

  const params = useParams()
  const { isLoading, data: cmsData } = useLandingPageBySlug(params.slug)
  function menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  function CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  const landing = useMemo(() => {
    if (!cmsData?.landing_page?.length) {
      return null
    }
    return cmsData?.landing_page[0]
  }, [cmsData])

  const SlideList = useMemo(() => {
    return landing ? [
      {
        textPosition: "text-center",
        category: "",
        title: landing.title,
        description:
          landing.description,
        buttonText: landing.cta_text,
        buttonLink: landing.cta_link,
      },
    ] : [
      {
        textPosition: "text-center",
        category: "",
        title: "Creative One Page",
        description:
          "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.",
        buttonText: "Contact Us",
        buttonLink: "/contact",
      },
    ];
  }, [landing])

  useEffect(() => {

    function onScroll() {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    }

    window.addEventListener("scroll", onScroll);

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  if (isLoading) {
    return <DarkFullScreenLoader />
  }
  if (!isLoading && !cmsData) {
    return <Redirect to="/404" />
  }
  return (
    <Fragment>
      <Helmet pageTitle='Home Particles' />

      {/* Start Header Area  */}
      <header className='header-area formobile-menu header--fixed default-color'>
        <div className='header-wrapper' id='header-wrapper'>
          <div className='header-left'>
            <div className='logo'>
              <a href='/'>
                <img
                  className='logo-1'
                  src='/assets/images/logo/logo-light.png'
                  alt='Logo Images'
                />
                <img
                  className='logo-2'
                  src='/assets/images/logo/logo-all-dark.png'
                  alt='Logo Images'
                />
              </a>
            </div>
          </div>
          <div className='header-right'>
            <nav className='mainmenunav d-lg-block'>
              <Scrollspy
                className='mainmenu'
                items={["home", "value", "about", "contact"]}
                currentClassName='is-current'
                offset={-200}>
                <li>
                  <a href='#home'>Home</a>
                </li>
                <li>
                  <a href='#value'>Our Value</a>
                </li>
                <li>
                  <a href='#about'>About</a>
                </li>
                <li>
                  <a href='#contact'>Contact</a>
                </li>
              </Scrollspy>
            </nav>
            {/* Start Humberger Menu  */}
            <div className='humberger-menu d-block d-lg-none pl--20 pl_sm--10'>
              <span
                onClick={menuTrigger}
                className='menutrigger text-white'>
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className='close-menu d-block d-lg-none'>
              <span onClick={CLoseMenuTrigger} className='closeTrigger'>
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
      {/* End Header Area  */}

      {/* Start Slider Area   */}
      <div
        className='slider-activation slider-creative-agency with-particles'
        id='home'>
        <div className='frame-layout__particles'>
          <Particles
            className='particle'
            options={{
              style: {
                position: "absolute",
              },
              fpsLimit: 100,
              interactivity: {
                detectsOn: "canvas",
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 100,
                    duration: 2,
                    opacity: 0.8,
                    size: 10,
                    color: "#888",
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 100,
                    duration: 0.4,
                    color: "#888",
                  },
                },
              },
              particles: {
                color: {
                  value: "#888",
                },
                links: {
                  color: "#888",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 6,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 2000,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5,
                },
              },
              detectRetina: true,
            }}
          />
        </div>
        <div className={classNames('im_modern_slider bg_image', {
          'bg_image--27': !landing.cover_image?.filename_disk
        })} style={{
          ...(landing.cover_image?.filename_disk ? {
            backgroundImage: `url(${cmsAssetUrl(landing.cover_image?.filename_disk, 1920)})`
          } : {})
        }}>
          {SlideList.map((value, index) => (
            <div
              className='slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center'
              key={index}>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 className='title'>{value.title}</h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className='description_style-2'>
                          {value.description}
                        </p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className='slide-btn'>
                          <a
                            className='btn-default btn-large'
                            href={`${value.buttonLink}`}>
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start Service Area  */}
      <div
        className='service-area creative-service-wrapper ptb--120 bg_color--1'
        id='value'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-12'>
              <div className='section-title text-left mb--30'>
                <span className='subtitle'>What we can do for you</span>
                <h2 className='title'>Services provide for you.</h2>
                <p className='description'>
                  {landing.description}
                </p>
              </div>
            </div>
          </div>
          <div className='row creative-service'>
            <div className='col-lg-12'>
              <ServiceList
                item='6'
                column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start About Area */}
      <div className='about-area ptb--120 bg_color--5' id='about'>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img className="w-100" src={landing.about_image?.filename_disk ? cmsAssetUrl(landing.about_image.filename_disk, 800) : "/assets/images/about/about-1.png"} alt="About Images" />
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <div className="icon">
                      <FiSend />
                    </div>
                    <h2 className="title">{landing.about_title}</h2>
                    <ul className="list-style--1">
                      {(landing.about_tags || []).map((tag) => (<li key={tag}><FiCheck /> {tag}</li>))}
                    </ul>
                    <div className="purchase-btn mt--30">
                      <Link className="btn-transparent" to="#">Contact</Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area */}

      {/* Start CounterUp Area */}
      <div className='rn-counterup-area pt--140 p pb--110 bg_color--5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='section-title text-center'>
                <span className='subtitle'>Experts growts</span>
                <h2 className='title'>Our Company Growth</h2>
              </div>
            </div>
          </div>
          <CounterTwo />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Contact Us */}
      <div className='rn-contact-us ptb--120 bg_color--5' id='contact'>
        <div className="contact-form--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--50">
                  <span className="subtitle">Let's Say Hi</span>
                  <h2 className="title">Contact With Us.</h2>
                  <div className="im_address mt--5">
                    <span>Contact Email:</span>
                    <a className="link im-hover" href="email">{landing.contact_email}</a>
                  </div>
                </div>
                <FormStyle />
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img src={landing.contact_image?.filename_disk ? cmsAssetUrl(landing.contact_image.filename_disk, 600) : "/assets/images/about/about-12.jpg"} alt="trydo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Contact Us */}

      {/* Start Footer Style  */}
      <FooterTwo />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className='backto-top'>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );

}

export default HomeParticlesDynamic;
