import React, { useMemo, useEffect } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import ClipLoader from "react-spinners/ClipLoader";
import TextLoop from "react-text-loop";
import FooterTwo from "../component/footer/FooterTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import { Link, useParams } from "react-router-dom";
import { usePersonalPortfolio } from "../hooks/usePortfolio";
import { Redirect } from "react-router-dom";
import PersonalPortfolioDarkHeader from "../component/header/PersonalPortfolioDarkHeader";
import { cmsAssetUrl } from "../utils/asset-url";
import classNames from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ProgressBar } from "react-bootstrap";
import DarkFullScreenLoader from "./DarkFullScreenLoader";

const PortfolioLanding = () => {
  const params = useParams();
  const { isLoading: isDataLoading, data: cmsData } = usePersonalPortfolio(
    params.username
  );
  const portfolio = useMemo(() => {
    if (!cmsData?.personal_portfolio?.length) {
      return null;
    }
    return cmsData.personal_portfolio[0];
  }, [cmsData?.personal_portfolio]);

  const SlideList = [
    {
      textPosition: "text-left",
      category: portfolio?.greetings || "Welcome to my World",
      description: "",
      buttonText: "Download CV",
      buttonLink: "",
    },
  ];

  let title = "About Me",
    description =
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered in some form, by injected humour.";
  const PostList = BlogContent.slice(0, 3);

  if (!params.username) {
    console.log(`username not found`);
    return <Redirect to='/404' />;
  }
  if (isDataLoading) {
    return <DarkFullScreenLoader />;
  }
  if (!isDataLoading && !portfolio) {
    console.log(`data not found`);
    return <Redirect to='/404' />;
  }
  if (!portfolio) {
    return <></>;
  }

  return (
    <div className='active-dark bg_color--9'>
      <Helmet pageTitle='Portfolio Landing' />

      <PersonalPortfolioDarkHeader
        homeLink='/'
        logo='symbol-dark'
        color='color-black'
        ctaLink={portfolio.cta_link}
        ctaText={portfolio.cta_text}
      />

      {/* Start Slider Area   */}
      <div id='home' className='fix'>
        <div className='slider-wrapper'>
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className={classNames(
                "slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image",
                {
                  "bg_image--25": !portfolio.cover_image?.filename_disk,
                }
              )}
              style={{
                backgroundImage: portfolio.cover_image?.filename_disk
                  ? `url(${cmsAssetUrl(
                    portfolio.cover_image?.filename_disk,
                    1920
                  )})`
                  : undefined,
              }}
              key={index}>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className='title'>
                        I’m {portfolio.name} <br />
                        <TextLoop>
                          {portfolio.keywords.map((k) => (
                            <span key={k}>{k}</span>
                          ))}
                        </TextLoop>{" "}
                      </h1>
                      <h2>{portfolio.description}</h2>
                      {value.description ? (
                        <p className='description'>{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className='slide-btn mt--30'>
                          <a
                            className='btn-default btn-border btn-opacity'
                            href={`${portfolio.cta_link || "#"}`}>
                            {portfolio.cta_text}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id='about' className='fix'>
        <div className='about-area ptb--120  bg_color--8'>
          <div className='about-wrapper'>
            <div className='container'>
              <div className='row row--35'>
                <div className='col-lg-5'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src={
                        portfolio.about_image?.filename_disk
                          ? cmsAssetUrl(portfolio.about_image.filename_disk)
                          : "/assets/images/about/about-8.jpg"
                      }
                      alt='About Images'
                    />
                  </div>
                </div>
                <div className='col-lg-7'>
                  <div className='about-inner inner'>
                    <div className='section-title'>
                      <span className='subtitle'>My About Details</span>
                      <h2 className='title mb--20'>{title}</h2>
                      <div
                        className='description mt_dec--20'
                        dangerouslySetInnerHTML={{
                          __html: portfolio.about_me,
                        }}></div>
                    </div>
                    <div className='row mt--40'>
                      <div>
                        {/* Start Tabs Area */}
                        <div className='tabs-area'>
                          <div className='container'>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <Tabs>
                                  <TabList className={`${"tab-style--1"}`}>
                                    <Tab>Main skills</Tab>
                                    <Tab>Awards</Tab>
                                    <Tab>Experience</Tab>
                                    <Tab>Education</Tab>
                                  </TabList>

                                  <TabPanel>
                                    <div className='single-tab-content'>
                                      <div
                                        className={`rn-progress-bar progress-bar--1 mt_dec--10`}>
                                        {(portfolio.main_skills || []).map(
                                          (ms) => (
                                            <div
                                              key={ms.skill.name}
                                              className='single-progress'>
                                              <h6 className='title'>
                                                {ms.skill.name}
                                              </h6>
                                              <ProgressBar
                                                now={ms.skill.percent}
                                              />
                                              <span className='label'>
                                                {ms.skill.percent}%
                                              </span>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </TabPanel>

                                  <TabPanel>
                                    <div className='single-tab-content'>
                                      <ul>
                                        {(portfolio.awards || []).map(
                                          (award) => (
                                            <li key={award.award.name}>
                                              <a href='/service'>
                                                {award.award.name}{" "}
                                                <span>
                                                  - {award.award.place}
                                                </span>
                                              </a>{" "}
                                              {award.award.start} -{" "}
                                              {award.award.end}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </TabPanel>

                                  <TabPanel>
                                    <div className='single-tab-content'>
                                      <ul>
                                        {(portfolio.experience || []).map(
                                          (exp) => {
                                            return (
                                              <li
                                                key={exp.experience.job_title}>
                                                <a href='#'>
                                                  {exp.experience.job_title}
                                                  <span>
                                                    {" "}
                                                    - {exp.experience.job_place}
                                                  </span>
                                                </a>{" "}
                                                {exp.experience.start} -{" "}
                                                {exp.experience.end}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </TabPanel>

                                  <TabPanel>
                                    <div className='single-tab-content'>
                                      <ul>
                                        {(portfolio.education || []).map(
                                          (edu) => {
                                            return (
                                              <li key={edu.education.title}>
                                                <a href='#'>
                                                  {edu.education.title}
                                                  <span>
                                                    {" "}
                                                    - {edu.education.location}
                                                  </span>
                                                </a>{" "}
                                                {edu.education.year}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </TabPanel>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* End Tabs Area */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Portfolio Area */}
      <div id='projects' className='fix'>
        <div className='portfolio-area pb--120 bg_color--8'>
          <div className='portfolio-sacousel-inner'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='section-title text-center mb--30 mb_sm--0'>
                    {/* <span className='subtitle'>My Complete project</span> */}
                    <h2 className='title'>Contact</h2>
                  </div>
                </div>
              </div>
              <div className='row'>
                <React.Fragment>
                  {portfolio.projects.slice(0, 3).map((value, index) => (
                    <div
                      className='col-lg-4 col-md-6 col-sm-6 col-12'
                      key={index}>
                      <div className='im_portfolio text-center mt--40'>
                        <div className='thumbnail_inner'>
                          <div className='thumbnail'>
                            <Link to='/portfolio-details'>{value.image}</Link>
                          </div>
                        </div>
                        <div className='content'>
                          <div className='inner'>
                            <div className='portfolio_heading'>
                              <h4 className='title'>
                                <Link to='/portfolio-details'>
                                  {value.title}
                                </Link>
                              </h4>
                            </div>
                            <div className='portfolio_hover'>
                              <p>{value.description}</p>
                            </div>
                          </div>
                        </div>
                        <Link
                          className='transparent_link'
                          to='/portfolio-details'></Link>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start Blog Area */}
      {/* <div id='blog' className='fix'>
        <div className='rn-blog-area pb--120 bg_color--8 mb-dec--30'>
          <div className='container'>
            <div className='row align-items-end'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                <div className='section-title text-center'>
                  <span className='subtitle'>My Latest Post</span>
                  <h2 className='title'>Latest News</h2>
                  <p className='description'>
                    There are many variations of passages of Lorem Ipsum
                    available, <br />
                    but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className='row mt--30 mt_sm--40'>
              {PostList.map((value, i) => (
                <div className='col-lg-4 col-md-6 col-12 mt--30' key={i}>
                  <div className='im_box'>
                    <div className='thumbnail'>
                      <Link to='/blog-details'>
                        <img
                          className='w-100'
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt='Blog Images'
                        />
                      </Link>
                    </div>
                    <div className='content'>
                      <div className='inner'>
                        <div className='content_heading'>
                          <div className='category_list'>
                            <Link to='/portfolio-details'>
                              {value.category}
                            </Link>
                          </div>
                          <h4 className='title'>
                            <Link to='/blog-details'>{value.title}</Link>
                          </h4>
                        </div>
                        <div className='content_footer'>
                          <Link
                            to='/blog-details'
                            className='rn-btn btn-opacity'>
                            Read More
                          </Link>
                        </div>
                      </div>
                      <Link
                        className='transparent_link'
                        to='/blog-details'></Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* End Blog Area */}

      {/* Start COntact Area */}
      <div id='contact' className='fix'>
        <div className='rn-contact-area pb--120 bg_color--8'>
          <ContactThree
            contactEmail={portfolio.contact_email}
            contactImages={
              portfolio.contact_img?.filename_disk
                ? cmsAssetUrl(portfolio.contact_img.filename_disk, 512)
                : "/assets/images/about/about-13.jpg"
            }
            contactTitle='Hire Me.'
          />
        </div>
      </div>
      {/* End Contact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className='backto-top'>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
