import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

function DarkFullScreenLoader() {
    return (
        <div className="active-dark bg_color--9" style={{ height: '100vh' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ClipLoader size={64} color="white" />
            </div>
        </div>
    )
}

export default DarkFullScreenLoader