import { useQuery } from "react-query";
import { graphQLClient } from "../api";
import { QUERY_PERSONAL_PORTFOLIO } from "../api/graphql-request-query/personal-portfolio";

export function usePersonalPortfolio(username) {
    return useQuery(["personal-portfolio", username], async () => {
        const resp = await graphQLClient.request(QUERY_PERSONAL_PORTFOLIO, {
            username
        });
        return resp;
    }, {
        enabled: username ? true : false,
    });
}