import { gql } from "graphql-request";

export const QUERY_LANDING_PAGE_BY_SLUG = gql`query LandingPageBySlug($slug: String!) {
    landing_page(filter: {
        slug: {
            _eq: $slug
        }
    }) {
        slug
        cover_image {
            filename_disk
        }
        title
        description
        cta_text
        cta_link
        about_image {
            filename_disk
        }
        about_title
        about_tags
        contact_email
        contact_image {
            filename_disk
        }
    }
}`